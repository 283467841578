import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query superAdminList($filter: UserFilter) {
    superAdminList(filter: $filter) {
      total
      data {
        id
        email
        name
        roles
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getLoggedInUser {
    getLoggedInUser {
      id
      name
      email
      password
      roles
      phoneNo
      createdBy
      profileImage
      isDisabled
    }
  }
`;
