import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../components/LoaderComponent';
import OrganizationForm from './components/OrganizationForm';
import { UPDATE_ORGANIZATION } from './graphql/Mutations';
import { GET_ORGANIZATIONS } from './graphql/Queries';

function EditOrganization() {
  const { id } = useParams();
  const [updateTenant, { loading: mutating }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      onError() {},
      refetchQueries: [
        {
          query: GET_ORGANIZATIONS,
          variables: {
            filter: { sortBy: { field: 'updatedAt', order: 'DESC' } }
          }
        }
      ]
    }
  );
  const { data, loading } = useQuery(GET_ORGANIZATIONS, {
    variables: { filter: { tenantId: Number(id) } },
    fetchPolicy: 'network-only'
  });

  if (loading) return <LoaderComponent />;

  return (
    <OrganizationForm
      mutation={updateTenant}
      id={id}
      organizationData={data?.tenantList?.data}
      mutating={mutating}
    />
  );
}

export default EditOrganization;
