import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput) {
    refreshToken(data: $data) {
      token
      refreshToken
    }
  }
`;
