import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADMIN_ASSET_LIST = gql`
  query adminAssetList($filter: AssetFilter!) {
    adminAssetList(filter: $filter) {
      total
      data {
        id
        eqcId
        imageUrl
        thumbnailUrl
        instructionId
        assetName
        createdAt
        eqc {
          eqcName
          projectEqcTypeId
          project {
            id
            name
          }
          tenant {
            id
            organizationName
          }
        }
        instruction {
          name
          project {
            id
            name
          }
          tenant {
            id
            organizationName
          }
        }
        eqcStage {
          name
          projectEqcTypeStageId
        }
        eqcStageItem {
          title
          projectEqcTypeStageItemId
        }
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getLoggedInUser {
    getLoggedInUser {
      id
      name
      email
      password
      roles
      phoneNo
      createdBy
      profileImage
      isDisabled
    }
  }
`;

export const TENANT_DROPDOWN_LIST = gql`
  query tenantDropdownList($filter: TenantFilter!) {
    tenantDropdownList(filter: $filter) {
      total
      data {
        id
        organizationName
        adminEmail
      }
    }
  }
`;

export const ASSET_PROJECT_DROPDOWN_LIST = gql`
  query assetProjectDropdownList($filter: ProjectFilter) {
    assetProjectDropdownList(filter: $filter) {
      total
      data {
        id
        name
        uniqueCode
      }
    }
  }
`;

export const ASSET_EQC_TYPE_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    assetProjectEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeStageDropdownList(
    $filter: ProjectEqcTypeStageFilter!
  ) {
    assetProjectEqcTypeStageDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeStageItemDropdownList(
    $filter: ProjectEqcTypeStageItemFilter!
  ) {
    assetProjectEqcTypeStageItemDropdownList(filter: $filter) {
      total
      data {
        id
        title
      }
    }
  }
`;
