import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import GalleryListing from './GalleryListing';

const GalleryWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.GALLERY} component={GalleryListing} />
    </Switch>
  );
};

export default GalleryWrapper;
