import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { BackArrow } from '../../assets/svg';
import { Password } from '../../assets/svg/login';
import { REGEX, ROUTES } from '../../common/constants';
import {
  checkPasswordCharacters,
  formValidatorRules
} from '../../common/utils';
import Portal from '../../components/Portal';
import history from '../../historyData';
import CheckPassword from './components/CheckPassword';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const { dispatch } = useContext(AppContext);
  const [selectedKey, setSelectedKey] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError() {},
    onCompleted() {
      dispatch({ type: 'LOGOUT' });
      history.push(ROUTES.LOGIN);
    }
  });
  const onFinish = (e) => {
    const { oldPassword, newPassword, confirmPassword } = e;
    if (newPassword !== confirmPassword) {
      message.error('Password does not match');
      return 0;
    }
    if (oldPassword === newPassword) {
      message.error('Old Password cannot be same as new password!');
      return 0;
    }
    changePassword({ variables: { data: { oldPassword, newPassword } } });
  };

  const handleChange = (e) => {
    setSelectedKey(checkPasswordCharacters(e?.target?.value));
  };
  const handleFocus = () => {
    setIsVisible(true);
  };
  const handleBlur = (e) => {
    if (REGEX?.PASSWORD.test(e?.target?.value)) {
      setIsVisible(false);
    }
  };
  return (
    <div>
      <Portal portalId="header-left-content">
        <h1>
          <BackArrow
            className="header-left-content-h1-svg pointer"
            onClick={() => history.goBack()}
          />
          Change Password
        </h1>
      </Portal>
      <div className="change-password-content">
        <div className="change-password-inside-content">
          <div className="gx-mb-4">
            <h1>Change your password</h1>
          </div>
          <Spin spinning={false}>
            <Form
              name="change-password"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              className="set-password-form"
            >
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[required]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[required, password]}
                className="password-input"
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e)}
                />
              </Form.Item>
              <CheckPassword selectedKey={selectedKey} isVisible={isVisible} />
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}
                rules={[
                  required,
                  password,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value !== getFieldValue('newPassword')) {
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <div className="form-buttons d-flex">
                <Form.Item>
                  <Button
                    shape="round"
                    className="cancel-button"
                    onClick={() => {
                      history.push(ROUTES.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    shape="round"
                    className="save-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
