import { Card } from 'antd';
import React from 'react';
import Portal from '../../components/Portal';
import Gallery from './Gallery';

const GalleryListing = () => {
  return (
    <div>
      <Portal portalId="header-left-content">
        <h1 className="portal-header">Gallery</h1>
      </Portal>
      <div className="users-content">
        <Card>
          <Gallery />
        </Card>
      </div>
    </div>
  );
};

export default GalleryListing;
