import { useMutation } from '@apollo/client';
import React from 'react';
import OrganizationForm from './components/OrganizationForm';
import { CREATE_ORGANIZATIONS } from './graphql/Mutations';
import { GET_ORGANIZATIONS } from './graphql/Queries';

function AddOrganization() {
  const [createTenant, { loading }] = useMutation(CREATE_ORGANIZATIONS, {
    onError() {},
    refetchQueries: [
      {
        query: GET_ORGANIZATIONS,
        variables: { filter: { sortBy: { field: 'updatedAt', order: 'DESC' } } }
      }
    ]
  });
  return <OrganizationForm mutation={createTenant} mutating={loading} />;
}

export default AddOrganization;
