import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import ChangePassword from '../modules/auth/ChangePassword';
import GalleryWrapper from '../modules/gallery';
import OrganizationWrapper from '../modules/organizations';
import UserWrapper from '../modules/users';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES.MAIN}
          render={() => <Redirect to={ROUTES.ORGANIZATION} />}
        />
        <Route path={ROUTES.USERS} component={UserWrapper} />
        <Route path={ROUTES.GALLERY} component={GalleryWrapper} />
        <Route path={ROUTES.ORGANIZATION} component={OrganizationWrapper} />
        <Route path={ROUTES.CHANGE} component={ChangePassword} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
