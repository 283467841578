import { Typography } from 'antd';
import { map } from 'lodash';
import React, { useMemo, useState } from 'react';

const { Paragraph } = Typography;
const EllipsisText = ({ text, className }) => {
  const [ellipsis, setEllipsis] = useState(true);
  return useMemo(() => {
    return (
      <div key={`${Math.random()}`} className={className}>
        <Paragraph
          className={`expand-text ${className}`}
          ellipsis={
            ellipsis
              ? {
                  rows: 4,
                  symbol: 'View More',
                  expandable: true,
                  onExpand: (e) => {
                    e.stopPropagation();
                    setEllipsis(false);
                  }
                }
              : false
          }
        >
          {map(text?.split('\n'), (val, index) => {
            return (
              <>
                {val}
                {text?.split('\n')?.length - 1 > index && <br />}
              </>
            );
          })}
        </Paragraph>

        {!ellipsis && (
          <div
            className="text-primary pointer"
            onClick={(e) => {
              e.stopPropagation();
              setEllipsis(true);
            }}
          >
            View Less
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, ellipsis]);
};
export default EllipsisText;
