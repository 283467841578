import * as eva from 'eva-icons';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Globe } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import Portal from '../../components/Portal';
import OrganizationTable from './components/OrganizationTable';

const Organizations = () => {
  const history = useHistory();
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Portal portalId="header-left-content">
        <h1 className="portal-header">
          <div className="d-flex">
            <Globe
              onClick={() => history.push(ROUTES.ORGANIZATION)}
              className="pointer"
            />
          </div>
          Organizations
        </h1>
      </Portal>
      <div className="organization-content">
        <div className="organization-table">
          <OrganizationTable />
        </div>
      </div>
    </div>
  );
};

export default Organizations;
