import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Email, LoginLogo } from '../../assets/svg/login';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgotPassword = () => {
  const history = useHistory();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError() {}
  });
  const onFinish = async (values) => {
    forgotPassword({
      variables: values
    })
      .then((val) => {
        if (val?.data) {
          message.success(
            'Reset password link sent to your email please check your email'
          );
        }
      })
      .catch((error) => {
        if (error && error.message) {
          message.error(error.message);
        } else {
          message.error('Something went wrong');
        }
      });
  };
  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h1>Forgot Password</h1>
          <p>Enter your e-mail address</p>
        </div>
        <Spin spinning={false}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[required, email]}
            >
              <Input
                addonBefore={<Email />}
                placeholder="Enter Email"
                maxLength={254}
              />
            </Form.Item>
            <div className="form-buttons d-flex">
              <Form.Item>
                <Button
                  className="cancel-button"
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  Back
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="save-button"
                  shape="round"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ForgotPassword;
