import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Error404 from '../../Error404';
import AddOrganization from './AddOrganization';
import OrganizationDetails from './components/organizationDetails/OrganizationDetails';
import EditOrganization from './EditOrganization';
import Organizations from './Organizations';

const OrganizationWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.ORGANIZATION} component={Organizations} />
      <Route path={`${ROUTES.ORGANIZATION}/add`} component={AddOrganization} />
      <Route
        path={`${ROUTES.ORGANIZATION}/edit/:id`}
        component={EditOrganization}
      />
      <Route
        path={`${ROUTES.ORGANIZATION}/:id/details`}
        component={OrganizationDetails}
      />
      <Route
        path={`${ROUTES.ORGANIZATION}/:id/users`}
        component={OrganizationDetails}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default OrganizationWrapper;
