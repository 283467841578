import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ORGANIZATIONS = gql`
  mutation createTenant($data: CreateTenantInput!) {
    createTenant(data: $data) {
      id
      organizationName
      adminEmail
      ownerName
      access
      userLimit
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateTenant($data: UpdateTenantInput!, $id: ID!) {
    updateTenant(data: $data, id: $id) {
      status
      message
    }
  }
`;

export const CREATE_ORGANIZATION_USERS = gql`
  mutation createSystemAdmin($data: CreateSystemAdminInput!) {
    createSystemAdmin(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_ORGANIZATION_USERS = gql`
  mutation updateSystemAdmin($id: ID!, $data: UpdateTenantUserInput!) {
    updateSystemAdmin(data: $data, id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation changeUserStatus($data: ChangeUserStatusInput!) {
    changeUserStatus(data: $data) {
      status
      message
    }
  }
`;
