import { Dropdown, Layout, Menu, Popover, Tag } from 'antd';
import * as eva from 'eva-icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { CloseIcon, InfoIconDark, MenuBar } from '../../../assets/svg';
import { LoginLogo } from '../../../assets/svg/login';
import { Account } from '../../../assets/svg/sider';
import { MODULES, ROUTES } from '../../../common/constants';

const { Header } = Layout;
const { SubMenu } = Menu;

const MobileHeader = ({ location: { pathname } }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onMenuSelect = (e) => {
    history.push(e.key);
    setVisible(false);
  };

  const menu = (
    <Menu selectedKeys={[`/${pathname.split('/')[1]}`]} onClick={onMenuSelect}>
      <Menu.Item key={ROUTES.ORGANIZATION} className="d-flex align-center">
        <span>{MODULES.ORGANIZATIONS}</span>
      </Menu.Item>
      <Menu.Item key={ROUTES.USERS} className="d-flex align-center">
        <span>{MODULES.USERS}</span>
      </Menu.Item>
      <Menu.Item key={ROUTES.GALLERY} className="d-flex align-center">
        <div className="d-flex align-center gallery-heading">
          {MODULES.GALLERY}
          <Tag className="tag-beta ml-5" color="#202A3F">
            BETA
          </Tag>
          <Popover
            placement="bottom"
            content={
              <>
                This is beta feature and may have bug and errors. This feature
                can be stopped without any prior notice.
              </>
            }
            overlayClassName="gallery-info"
            trigger={['click']}
          >
            <InfoIconDark
              height="18px"
              width="18px"
              onClick={(e) => {
                e?.stopPropagation();
              }}
            />
          </Popover>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center width-percent-100 ">
        <div className="d-flex align-center">
          <Dropdown
            overlay={menu}
            trigger={['click']}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('#nav-bar-dropdown')
            }
            onVisibleChange={(value) => setVisible(value)}
            visible={visible}
          >
            <div className="mobile-menu-trigger d-flex">
              {visible ? (
                <CloseIcon height={34} width={34} />
              ) : (
                <MenuBar height={34} width={34} />
              )}
            </div>
          </Dropdown>
          <div className="header-logo">
            <Link to={ROUTES.MAIN} className="gx-site-logo mr-25">
              <LoginLogo />
            </Link>
          </div>
        </div>
      </div>
      <div id="nav-bar-dropdown" />
      <div className="header-right">
        <Menu mode="horizontal">
          <SubMenu icon={<Account />} title="Account">
            <Menu.Item onClick={() => history.push(ROUTES.CHANGE)}>
              Change Password
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                history.push(ROUTES.LOGOUT);
              }}
            >
              Logout
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </Header>
  );
};
export default withRouter(MobileHeader);
