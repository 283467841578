import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Password } from '../../assets/svg/login';
import { REGEX, ROUTES } from '../../common/constants';
import {
  checkPasswordCharacters,
  formValidatorRules
} from '../../common/utils';
import CheckPassword from './components/CheckPassword';
import { RESET_PASSWORD, TOKEN_VALIDATION } from './graphql/Mutations';

const { required, passwordRules } = formValidatorRules;

const ResetPassword = (props) => {
  const { history, history: { location: { search } = {} } = {} } = props;
  const [selectedKey, setSelectedKey] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const getQuery = () => {
    return new URLSearchParams(search);
  };
  const [tokenValid, { loading }] = useMutation(TOKEN_VALIDATION, {
    onError() {}
  });
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError() {}
  });

  useEffect(() => {
    const id = getQuery().get('uid');
    const resetToken = getQuery().get('token');
    tokenValid({ variables: { data: { token: resetToken, uId: Number(id) } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenValid]);

  const onFinish = ({ password }) => {
    const id = getQuery().get('uid');
    const resetToken = getQuery().get('token');
    resetPassword({
      variables: {
        data: { password: password, uId: Number(id), token: resetToken }
      },
      context: {
        headers: {
          'x-token': resetToken
        }
      }
    })
      .then(() => {
        history.replace(ROUTES.LOGIN);
      })
      .catch((e) => {
        return e;
      });
  };

  const handleChange = (e) => {
    setSelectedKey(checkPasswordCharacters(e?.target?.value));
  };
  const handleFocus = () => {
    setIsVisible(true);
  };
  const handleBlur = (e) => {
    if (REGEX?.PASSWORD.test(e?.target?.value)) {
      setIsVisible(false);
    }
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0 set-password-form"
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[required, passwordRules]}
              className="password-input"
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Password"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={(e) => handleBlur(e)}
              />
            </Form.Item>
            <CheckPassword selectedKey={selectedKey} isVisible={isVisible} />
            <Form.Item
              name="retype-password"
              label="Confirm Password"
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value !== getFieldValue('password')) {
                      return Promise.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Retype password"
              />
            </Form.Item>
            <div className="form-buttons d-flex">
              <Form.Item>
                <Button
                  className="cancel-button"
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  className="save-button"
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ResetPassword;
