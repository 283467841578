import { useLazyQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { nth, parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ForwardArrow, Globe } from '../../../../assets/svg';
import { ROUTES } from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import { GET_ORGANIZATIONS } from '../../graphql/Queries';
import Details from './pages/Details';
import Users from './pages/Users';

const OrganizationDetails = () => {
  const { id } = useParams();
  const { TabPane } = Tabs;

  const { pathname } = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('details');
  const navPagination = history?.location?.state?.navPagination;
  const navFilter = history?.location?.state?.navFilter;
  const currentPath = history?.location?.state?.currentPath;

  const [fetchOrganizationData, { data }] = useLazyQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: { tenantId: parseInt(id) },
      sortBy: { field: 'isDisabled', order: 'ASC' }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    fetchOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(currentPath, {
          navFilter,
          navPagination
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const fetchData = nth(data?.tenantList?.data, 0);

  useEffect(() => {
    if (pathname?.includes('details')) {
      setActiveKey('details');
    } else {
      setActiveKey('users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="details">
        <Portal portalId="header-left-content">
          <h1>
            <div className="d-flex">
              <Globe
                onClick={() =>
                  history.push(ROUTES.ORGANIZATION, {
                    navPagination,
                    navFilter
                  })
                }
                className="pointer"
              />
              <ForwardArrow />
            </div>
            <span className="break-all-words">
              {fetchData?.organizationName}
            </span>
          </h1>
        </Portal>

        <div className="details-content">
          <Tabs
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key);
              history.push(`${ROUTES.ORGANIZATION}/${id}/${key}`, {
                navPagination,
                navFilter
              });
            }}
          >
            <TabPane tab="Details" key="details" className="details-tab">
              <div className="organization-details">
                <Details
                  data={data}
                  fetchOrganizationData={fetchOrganizationData}
                  pageFilter={{ navPagination, navFilter }}
                />
              </div>
            </TabPane>
            <TabPane tab="Users" key="users" forceRender>
              <div className="users">
                <Users id={id} data={data} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default OrganizationDetails;
